import React from 'react';
import { Helmet } from 'react-helmet';
import StockInfo from './StockInfo';
import './App.css';

function App() {
  return (
    <>
      <Helmet>
        <title>Calcul de la Valeur Intrinsèque</title>
        <meta name="description" content="Calculez la valeur intrinsèque d'une entreprise en analysant ses fondamentaux financiers : PE ratio, EPS, dividendes et autres métriques essentielles." />
        <meta name="keywords" content="valeur intrinsèque, analyse financière, PE ratio, EPS, ROE, dividendes" />
        <meta property="og:title" content="Calcul de la Valeur Intrinsèque" />
        <meta property="og:description" content="Outil d'analyse financière pour calculer la valeur intrinsèque des entreprises" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://valeur-intrinseque.fr" />
      </Helmet>
      <StockInfo />
    </>
  );
}

export default App;
