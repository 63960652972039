import axios from 'axios';

const apiKey = '80c54b4af199dbc3b43777ca72ab1d07';

export async function fetchKeyMetrics(symbol) {

  const options = {
    method: 'GET',
    url: `https://financialmodelingprep.com/api/v3/key-metrics/${symbol}?period=annual&apikey=${apiKey}`
  };

  try {
    const response = await axios.request(options);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des données:', error);
    return null;
  }
}

  