import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCZAmm-RVQTCS5voDpLLus4UTQ5ZBIiZtg",
  authDomain: "valeur-intrinseque.firebaseapp.com",
  projectId: "valeur-intrinseque",
  storageBucket: "valeur-intrinseque.firebasestorage.app",
  messagingSenderId: "1054712142976",
  appId: "1:1054712142976:web:4521845286e9d084c7da65"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();