import React, { useState, useEffect } from 'react';
import { fetchKeyMetrics } from './api';
import './FinancialMetrics.css';
import AuthModal from './AuthModal';
import { auth } from './firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';

const Disclaimer = () => (
  <div className="bg-amber-50 border-l-4 border-amber-400 p-3 sm:p-4 mb-4 sm:mb-6">
    <div className="flex">
      <div className="flex-shrink-0">
        <svg className="h-4 w-4 sm:h-5 sm:w-5 text-amber-400" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
        </svg>
      </div>
      <div className="ml-2 sm:ml-3">
        <p className="text-xs sm:text-sm text-amber-700">
          <strong>Avertissement :</strong> Les informations fournies sont à titre indicatif uniquement et ne constituent pas des conseils en investissement. Consultez un professionnel avant toute décision.
        </p>
      </div>
    </div>
  </div>
);

function StockInfo() {
  const [stockSymbol, setStockSymbol] = useState('');
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [marketCap, setMarketCap] = useState('');
  const [peRatio, setPeRatio] = useState('');
  const [epsLastFive, setEpsLastFive] = useState([]);
  const [debtEquityRatio, setDebtEquityRatio] = useState('');
  const [dividendYield, setDividendYield] = useState('');
  const [freeCashFlowLastFive, setFreeCashFlowLastFive] = useState([]);
  const [currentRatio, setCurrentRatio] = useState('');
  const [interestCoverageRatio, setInterestCoverageRatio] = useState('');
  const [roe, setRoe] = useState('');
  const [date, setDate] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(null);
  const [favorites, setFavorites] = useState([]);
  const db = getFirestore();
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    // Vérifier s'il y a un utilisateur dans le localStorage au chargement
    const savedUser = localStorage.getItem('user');
    if (savedUser) {
      setUser(JSON.parse(savedUser));
    }

    // Écouter les changements d'état d'authentification
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        const userData = {
          displayName: currentUser.displayName,
          email: currentUser.email,
          photoURL: currentUser.photoURL
        };
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
      } else {
        setUser(null);
        localStorage.removeItem('user');
      }
    });

    // Nettoyer l'écouteur lors du démontage du composant
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const loadFavorites = async () => {
      if (user) {
        const userRef = doc(db, 'users', user.email);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setFavorites(userDoc.data().favorites || []);
        } else {
          await setDoc(userRef, { favorites: [] });
        }
      }
    };

    loadFavorites();
  }, [user, db]);

  const handleSearch = (e) => {
    e.preventDefault();
    if (!user) {
      setShowModal(true);
    } else {
      handleSubmit(e);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSearching(true);
    try {
      const data = await fetchKeyMetrics(stockSymbol);
      if (!data || data.length === 0) {
        setError(`Aucune donnée trouvée pour ${stockSymbol}.`);
        return;
      }
      const metrics = data[0];
      setName(metrics.symbol);
      setMarketCap(metrics.marketCap);
      setPeRatio(metrics.peRatio.toFixed(2));
      
      const freeCashFlowValues = data.slice(0, 5)
        .map((item) => (item.freeCashFlowPerShare * 
          (metrics.marketCap / (metrics.peRatio * metrics.netIncomePerShare)))
          .toFixed(2))
        .reverse();
      setFreeCashFlowLastFive(freeCashFlowValues);
      
      const epsValues = data.slice(0, 5)
        .map((item) => item.netIncomePerShare.toFixed(2))
        .reverse();
      setEpsLastFive(epsValues);
      
      setDebtEquityRatio(metrics.debtToEquity.toFixed(2));
      setDividendYield(metrics.dividendYield.toFixed(2));
      setCurrentRatio(metrics.currentRatio.toFixed(2));
      setInterestCoverageRatio(metrics.interestCoverage.toFixed(2));
      setRoe(metrics.roe.toFixed(2));
      setDate(metrics.date);
    } catch (err) {
      setError(`Erreur lors de la récupération des données : ${err.message}`);
      setIsSearching(false);
      setName('');
      setMarketCap('');
      setPeRatio('');
      setEpsLastFive([]);
      setDebtEquityRatio('');
      setDividendYield('');
      setFreeCashFlowLastFive([]);
      setCurrentRatio('');
      setInterestCoverageRatio('');
      setRoe('');
    }
  };

  const getEpsClass = (index, total) => {
    const step = Math.floor(100 / total);
    return `eps-color-${(index + 1) * step}`;
  };

  const getClass = (value, min, max, trend = 'increasing') => {
    if (Array.isArray(value)) {
      let isCorrectTrend = true;
      for (let i = 1; i < value.length; i++) {
        const current = parseFloat(value[i]);
        const previous = parseFloat(value[i - 1]);
        if (trend === 'increasing' && current < previous) {
          isCorrectTrend = false;
          break;
        } else if (trend === 'decreasing' && current > previous) {
          isCorrectTrend = false;
          break;
        }
      }
      return isCorrectTrend ? 'good' : 'bad';
    } else if (typeof value === 'number') {
      return (value >= min && value <= max) ? 'good' : 'bad';
    } else {
      return 'neutral';
    }
  };

  const formatNumber = (num) => {
    if (!num) return 'N/A';
    
    // Convertir en nombre si c'est une chaîne
    num = typeof num === 'string' ? parseFloat(num) : num;

    // Formatter selon la taille du nombre
    if (Math.abs(num) >= 1e12) { // Billions/Trillions
      return (num / 1e12).toFixed(2) + ' B';
    } else if (Math.abs(num) >= 1e9) { // Milliards
      return (num / 1e9).toFixed(2) + ' Mrd';
    } else if (Math.abs(num) >= 1e6) { // Millions
      return (num / 1e6).toFixed(2) + ' M';
    } else if (Math.abs(num) >= 1e3) { // Milliers
      return (num / 1e3).toFixed(2) + ' k';
    } else {
      return num.toFixed(2);
    }
  };

  const getResults = () => {
    return [
      {
        name: 'PE Ratio',
        value: peRatio ? parseFloat(peRatio) : 'N/A',
        unit: 'fois',
        description: "Mesure la valeur de l'action par rapport à ses bénéfices. Elle doit se trouver entre <strong>10 et 20</strong>",
        min: 10,
        max: 20,
        className: getClass(parseFloat(peRatio), 10, 20)
      },
      {
        name: 'EPS',
        value: epsLastFive && epsLastFive.length > 0 ? (
          <span>
            {epsLastFive.map((eps, index) => (
              <span key={index} className={getEpsClass(index, epsLastFive.length)}>
                {eps} {index < epsLastFive.length - 1 ? ' ; ' : ''}
              </span>
            ))}
          </span>
        ) : 'N/A',
        unit: '',
        description: "L'Earnings Per Share est le bénéfice de l'entreprise divisé par le nombre d'actions. On doit trouver une <strong>croissance régulière d'année en année</strong>",
        className: getClass(epsLastFive, 0, Infinity, 'increasing')
      },
      {
        name: 'Dette/Fonds propres',
        value: debtEquityRatio ? parseFloat(debtEquityRatio) : 'N/A',
        unit: '',
        description: "Le ratio dette sur fonds propres mesure l'endettement de l'entreprise. Il doit être <strong>inférieur à 1</strong>",
        min: 0,
        max: 1,
        className: getClass(parseFloat(debtEquityRatio), 0, 1)
      },
      {
        name: 'Rendement dividendes',
        value: dividendYield ? parseFloat(dividendYield) : 'N/A',
        unit: '%',
        description: "Le rendement des dividendes est le rapport entre le dividende versé et le cours de l'action. Il doit être compris entre <strong>2% et 6%</strong>",
        min: 2,
        max: 6,
        className: getClass(parseFloat(dividendYield), 2, 6)
      },
      {
        name: 'Flux de trésorerie libre',
        value: freeCashFlowLastFive && freeCashFlowLastFive.length > 0 ? (
          <span>
            {freeCashFlowLastFive.map((fcf, index) => (
              <span key={index} className={getEpsClass(index, freeCashFlowLastFive.length)}>
                {formatNumber(fcf)} {index < freeCashFlowLastFive.length - 1 ? ' ; ' : ''}
              </span>
            ))}
          </span>
        ) : 'N/A',
        unit: '',
        description: "Le flux de trésorerie libre représente l'argent disponible après les investissements. Il doit être <strong>positif et croissant</strong>",
        className: getClass(freeCashFlowLastFive, 0, Infinity, 'increasing')
      },
      {
        name: 'Ratio de liquidité courante',
        value: currentRatio ? parseFloat(currentRatio) : 'N/A',
        unit: '',
        description: "Le ratio de liquidité courante mesure la capacité à payer les dettes à court terme. Il doit être <strong>supérieur à 1</strong>",
        min: 1,
        max: Infinity,
        className: getClass(parseFloat(currentRatio), 1, Infinity)
      },
      {
        name: 'Ratio de couverture des intérêts',
        value: interestCoverageRatio ? parseFloat(interestCoverageRatio) : 'N/A',
        unit: '',
        description: "Le ratio de couverture des intérêts mesure la capacité à payer les intérêts de la dette. Il doit être <strong>supérieur à 1.5</strong>",
        min: 1.5,
        max: Infinity,
        className: getClass(parseFloat(interestCoverageRatio), 1.5, Infinity)
      },
      {
        name: 'ROE',
        value: roe ? parseFloat(roe) : 'N/A',
        unit: '%',
        description: "Le Return on Equity mesure la rentabilité des fonds propres. Il doit être <strong>supérieur à 15%</strong>",
        min: 15,
        max: Infinity,
        className: getClass(parseFloat(roe), 15, Infinity)
      }
    ];
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      localStorage.removeItem('user');
    } catch (error) {
      console.error("Erreur lors de la déconnexion:", error);
    }
  };

  const toggleFavorite = async (symbol) => {
    if (!user) return;

    const userRef = doc(db, 'users', user.email);
    
    if (favorites.includes(symbol)) {
      // Retirer des favoris
      await updateDoc(userRef, {
        favorites: arrayRemove(symbol)
      });
      setFavorites(favorites.filter(fav => fav !== symbol));
    } else {
      // Ajouter aux favoris
      await updateDoc(userRef, {
        favorites: arrayUnion(symbol)
      });
      setFavorites([...favorites, symbol]);
    }
  };

  // Ajoutez des données structurées Schema.org
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "Calculateur de Valeur Intrinsèque",
    "description": "Outil d'analyse financière pour calculer la valeur intrinsèque des actions",
    "applicationCategory": "Finance Application",
    "operatingSystem": "Web Browser"
  };

  const handleReturn = () => {
    setName('');
    setStockSymbol('');
    setIsSearching(false);
  };

  return (
    <main className="bg-gray-50">
      {error && <div role="alert" className="alert alert-danger">{error}</div>}
      
      {/* Hero Section */}
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-sky-900 to-sky-700 pb-8 pt-10 sm:pb-16 sm:pt-14">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl py-16 sm:py-32 lg:py-20">
            <div className="text-center">
              <h1 className="text-3xl sm:text-4xl font-bold tracking-tight text-white sm:text-6xl">
                Calculateur de Valeur Intrinsèque
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                Analysez la valeur réelle de vos investissements grâce à notre outil d'analyse financière avancé
              </p>
              {user && (
                <div className="mt-4 flex justify-center gap-4 text-sm text-gray-200">
                  <span>{user.displayName}</span>
                  <button onClick={handleLogout} className="underline hover:text-white">
                    Déconnexion
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Disclaimer */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-4 sm:mt-8 mb-8 sm:mb-12 relative z-10">
        <Disclaimer />
      </div>

      {/* Search Section avec bouton retour */}
      <div className="relative z-20">
        <div className="mx-auto max-w-md px-4 sm:px-6">
          <div className="flex flex-col gap-3 sm:gap-4">
            {isSearching && (
              <button
                onClick={handleReturn}
                className="self-start flex items-center px-2 sm:px-3 py-1.5 sm:py-2 text-sky-700 hover:text-sky-900 transition-colors bg-white rounded-md shadow-sm hover:bg-gray-50"
              >
                <svg
                  className="w-4 h-4 sm:w-5 sm:h-5 mr-1 sm:mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10 19l-7-7m0 0l7-7m-7 7h18"
                  />
                </svg>
                <span className="text-sm sm:text-base">Retour</span>
              </button>
            )}
            <form onSubmit={handleSearch} className="w-full">
              <div className="relative overflow-hidden rounded-lg shadow-lg">
                <input
                  type="text"
                  className="block w-full px-3 sm:px-4 py-3 sm:py-4 text-sm sm:text-base text-gray-900 placeholder-gray-500 bg-white"
                  value={stockSymbol}
                  onChange={(e) => setStockSymbol(e.target.value)}
                  placeholder="Symbole de l'action (ex: AAPL)"
                  aria-label="Symbole de l'action"
                />
                <button
                  type="submit"
                  className="absolute right-0 top-0 h-full px-4 sm:px-6 bg-sky-600 text-white text-sm sm:text-base font-semibold hover:bg-sky-700"
                >
                  <span className="hidden sm:inline">Analyser</span>
                  <span className="sm:hidden">OK</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Content Sections */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-12 sm:py-16 relative z-0">
        {/* Affichage des favoris quand aucune recherche n'est active */}
        {user && favorites.length > 0 && !name && (
          <div className="bg-white rounded-xl shadow-lg p-8 mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">Vos actions favorites</h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
              {favorites.map((symbol) => (
                <button
                  key={symbol}
                  onClick={() => {
                    setStockSymbol(symbol);
                    handleSubmit({ preventDefault: () => {} });
                  }}
                  className="group relative bg-gray-50 rounded-lg p-4 hover:bg-sky-50 transition-colors"
                >
                  <div className="flex items-center justify-between">
                    <span className="font-medium text-gray-900 group-hover:text-sky-700">
                      {symbol}
                    </span>
                    <div className="flex items-center gap-2">
                      <svg
                        className="w-5 h-5 text-yellow-500 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                      </svg>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleFavorite(symbol);
                        }}
                        className="opacity-0 group-hover:opacity-100 text-red-500 hover:text-red-700"
                        aria-label="Retirer des favoris"
                      >
                        <svg
                          className="w-5 h-5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        )}

        {/* Metrics Display */}
        {name ? (
          <div className="bg-white rounded-xl shadow-lg p-8 mb-12">
            <div className="border-b border-gray-200 pb-6 mb-6">
              <div className="flex justify-between items-center">
                <div>
                  <h2 className="text-3xl font-bold text-gray-900">{name}</h2>
                  <p className="text-xl text-gray-600">
                    Capitalisation : {formatNumber(marketCap)} USD
                  </p>
                </div>
                {user && (
                  <button
                    onClick={() => toggleFavorite(name)}
                    className="p-2 rounded-full hover:bg-gray-100"
                    aria-label={favorites.includes(name) ? "Retirer des favoris" : "Ajouter aux favoris"}
                  >
                    <svg
                      className={`w-6 h-6 ${
                        favorites.includes(name) ? 'text-yellow-500 fill-current' : 'text-gray-400'
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                    </svg>
                  </button>
                )}
              </div>
              {date && (
                <p className="text-sm text-gray-500 mt-2">
                  Données du {date}
                </p>
              )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {getResults().map((result) => (
                <div key={result.name} className="bg-gray-50 rounded-lg p-6 hover:shadow-md transition-shadow">
                  <div className="flex items-center gap-3 mb-3">
                    <div className={`w-3 h-3 rounded-full ${result.className}`}></div>
                    <h3 className="font-semibold text-gray-900">{result.name}</h3>
                  </div>
                  <p className="text-2xl font-bold text-gray-900 mb-2">
                    {result.value} {result.unit}
                  </p>
                  <p className="text-sm text-gray-600" dangerouslySetInnerHTML={{ __html: result.description }}></p>
                </div>
              ))}
            </div>
          </div>
        ) : (
          /* Info Sections when no search */
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl font-bold text-gray-900 mb-4">Comprendre la Valeur Intrinsèque</h2>
              <p className="text-gray-600">
                La valeur intrinsèque représente la valeur réelle d'une entreprise, au-delà des fluctuations du marché.
              </p>
            </div>
            
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl font-bold text-gray-900 mb-4">Métriques Clés</h2>
              <ul className="space-y-2 text-gray-600">
                <li>• PE Ratio (10-20 idéal)</li>
                <li>• EPS en croissance</li>
                <li>• ROE &gt; 15%</li>
                <li>• Dette/Fonds Propres &lt; 1</li>
              </ul>
            </div>
            
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl font-bold text-gray-900 mb-4">Avantages</h2>
              <ul className="space-y-2 text-gray-600">
                <li>• Analyse complète</li>
                <li>• Visualisation claire</li>
                <li>• Historique sur 5 ans</li>
                <li>• Recommandations précises</li>
              </ul>
            </div>
          </div>
        )}
      </div>
      <AuthModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSuccess={(user) => setUser(user)}
      />
      <script type="application/ld+json">
        {JSON.stringify(jsonLd)}
      </script>
    </main>
  );
}

export default StockInfo;